// extracted by mini-css-extract-plugin
export var root = "services-module--root--cd3d8";
export var header = "services-module--header--90197";
export var spacer30 = "services-module--spacer30--21199";
export var gridSpan2 = "services-module--gridSpan2--0ed40";
export var gridLayout = "services-module--gridLayout--06788";
export var descriptionSection = "services-module--descriptionSection--dd4fa";
export var beforeAfterSection = "services-module--beforeAfterSection--72d19";
export var subsectionHeader = "services-module--subsectionHeader--b7d18";
export var accordionSection = "services-module--accordionSection--cfcd8";
export var bucketTestContent = "services-module--bucketTestContent--ed14c";
export var accordionContent = "services-module--accordionContent--76226";
export var gridSection = "services-module--gridSection--68d13";
export var imagesSection = "services-module--imagesSection--4d878";
export var image = "services-module--image--c1888";