import React from 'react';
import * as styles from './services.module.css';

import Container from '../../components/Container';
import Layout from '../../components/Layout';

const AboveGroundPage = () => {
  return (<Layout disablePaddingBottom>
    <div className={styles.root}>
        <Container>
            <div data-fade-in-section>
                <h1 className={styles.header}>Above-ground Pool Installation</h1>
                <div className={styles.descriptionSection}>
                    <p>
                        Pristine Pool & Spa has installed beautiful and affordable above-ground pools for over 40 years. Our experienced team works closely with customers from the design phase of the project to completion. We offer numerous installation and design options such as on-the-ground or partial inground, deep end or flat bottom, and solid color or pattern liners.
                        <br></br>
                        <br></br>
                        We install all types of above-ground pools; however, we believe the <a href="https://doughboypools.com/" target="_blank">Doughboy brand</a> is the highest
                        quality in this category. 
                    </p>
                    <p>
                        Doughboy offers round and oval-shaped pools with many different sizes to choose from. Doughboy vinyl liners are very durable and will last 10-20 years. Pristine Pool & Spa is the most experienced Doughboy pool installer in the SF North Bay. We work closely with the local authorized Doughboy pool dealer and offer customers assistance with numerous options for pool equipment and accessories. Please contact us for a site review and project estimate.
                    </p>
                </div>
                <section className={styles.imagesSection}>
                    <img data-fade-in-section className={styles.image} height={480} src="/pool_aboveground_before.jpeg"></img>
                    <img data-fade-in-section className={styles.image} height={480} src="/pool_aboveground_after_2.jpeg"></img>
                    <img data-fade-in-section className={styles.image} height={480} src="/pool_aboveground_after_1.jpeg"></img>
                </section>
            </div>
        </Container>
    </div>
  </Layout>);
};

export default AboveGroundPage;